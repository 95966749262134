/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dislike': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.435 9.62a1.475 1.475 0 01-.432-1V7.195c0-.181.035-.361.1-.53l2.155-4.982c.104-.257.283-.477.515-.63.23-.155.502-.236.78-.233h6.37c.378-.01.742.14 1 .415.265.273.419.634.433 1.013V9.32a1.474 1.474 0 01-.432 1L6.274 15l-.764-.764a.993.993 0 01-.3-.73v-.232l.7-3.254H1.435a1.351 1.351 0 01-1-.4zM12.75.82h2.853v8.5H12.75V.82z" _fill="#9FB0C9"/>'
  }
})
